import React, { useState } from "react";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { FaGlobe } from "react-icons/fa";
import { motion } from "framer-motion";
import { images } from "../../constants";
import "./Navbar.scss";
import { useLocalization } from "../../useLocalization";

const Navbar = () => {
  const { t, locale, changeLanguage } = useLocalization();
  const [toggle, setToggle] = useState(false);

  const toggleLanguage = () => {
    const newLanguage = locale === "en" ? "ar" : "en";
    changeLanguage(newLanguage);
  };

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.logoText} alt="logo text" />
      </div>
      <ul className="app__navbar-links">
        {["home", "about", "work", "skills", "contact"].map((item) => (
          <li className="app__flex p-text" key={`link-${item}`}>
            <div />
            <a href={`#${item}`}>{t(`navbar.${item}`)}</a>
          </li>
        ))}
        <li className="app__flex p-text ">
          <div />
          <button onClick={toggleLanguage} className="language-switch-btn">
            {locale === "en" ? "العربية" : "EN"}
          </button>
        </li>
      </ul>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: "easeOut" }}
            className="app__navbar-mobile">
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {["home", "about", "work", "skills", "contact"].map((item) => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {t(`navbar.${item}`)}
                  </a>
                </li>
              ))}
              <li className="app__navbar-switch">
                <button
                  onClick={toggleLanguage}
                  className="language-switch-btn">
                  {locale === "en" ? "العربية" : "English"}
                </button>
              </li>
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
