import React from "react"; //+

import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { LocalizationProvider } from "./LocalizationContext";

ReactDOM.render(
  <LocalizationProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </LocalizationProvider>,
  document.getElementById("root")
);
