import { useContext } from "react";
import { LocalizationContext } from "./LocalizationContext";

export const useLocalization = () => {
  const { locale, messages, changeLanguage } = useContext(LocalizationContext);

  const t = (key, params = {}) => {
    let translation =
      key.split(".").reduce((obj, i) => (obj ? obj[i] : null), messages) || key;
    Object.keys(params).forEach((param) => {
      translation = translation.replace(`{{${param}}}`, params[param]);
    });
    return translation;
  };

  return { t, locale, changeLanguage };
};
