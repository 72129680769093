import React, { useState } from "react";
import emailjs from "emailjs-com";
import { AppWrap, MotionWrap } from "../../wrapper";
import { images } from "../../constants";
import "./Footer.scss";
import { useLocalization } from "../../useLocalization";

const Footer = () => {
  const { t } = useLocalization();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { name: username, email: useremail, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const serviceID = "service_1u93wve";
    const templateID = "template_iod04fj";
    const userID = "KVC2TSS9gNJYBMuKr";

    const templateParams = {
      username,
      useremail,
      message,
    };

    emailjs.send(serviceID, templateID, templateParams, userID).then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
        setLoading(false);
        setIsFormSubmitted(true);
        setFormData({ name: "", email: "", message: "" });
      },
      (error) => {
        console.log("FAILED...", error);
        setLoading(false);
      }
    );
  };

  return (
    <>
      <h2 className="head-text">{t("footer.title")}</h2>

      <div className="app__footer-cards">
        <div className="app__footer-card">
          <img src={images.email} alt="email" />
          <a href="mailto:support@innolabs.ai" className="p-text">
            support@innolabs.ai
          </a>
        </div>
        <div className="app__footer-card">
          <img src={images.mobile} alt="phone" />
          <a href="tel:+2 01158889193" className="p-text">
            00201158889193
          </a>
        </div>
      </div>

      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <div className="app__flex">
              <input
                className="p-text"
                type="text"
                placeholder={t("footer.name")}
                name="name"
                value={username}
                onChange={handleChangeInput}
                required
              />
            </div>
            <div className="app__flex">
              <input
                className="p-text"
                type="email"
                placeholder={t("footer.email")}
                name="email"
                value={useremail}
                onChange={handleChangeInput}
                required
              />
            </div>
            <div>
              <textarea
                className="p-text"
                placeholder={t("footer.message")}
                value={message}
                name="message"
                onChange={handleChangeInput}
                required
              />
            </div>
            <button type="submit" className="p-text" disabled={loading}>
              {loading ? t("footer.sending") : t("footer.send_message")}{" "}
              {/* Localized button text */}
            </button>
          </form>
        </div>
      ) : (
        <div>
          <h3 className="head-text">{t("footer.thank_you")}</h3>{" "}
        </div>
      )}
      <div className="copyright">
        <p className="p-text" style={{ fontFamily: "Raleway" }}>
          {t("app.copyright")}
        </p>
        <p className="p-text" style={{ fontFamily: "Raleway" }}>
          {t("app.all_rights_reserved")}{" "}
        </p>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, "app__footer"),
  "contact",
  "app__whitebg"
);
