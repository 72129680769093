import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { AppWrap } from "../../wrapper";
import { images } from "../../constants";
import "./Header.scss";
import { useLocalization } from "../../useLocalization";

const Header = () => {
  const { t } = useLocalization();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY,
      });
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const calculateMovement = (xFactor, yFactor) => ({
    x: (mousePosition.x - window.innerWidth / 2) * xFactor,
    y: (mousePosition.y - window.innerHeight / 2) * yFactor,
  });

  return (
    <div className="app__header app__flex">
      <motion.div className="app__header-circles-left">
        {[images.react, images.python, images.figma].map((circle, index) => (
          <motion.div
            className="circle-cmp app__flex"
            key={`circle-left-${index}`}
            animate={calculateMovement(0.03 * (index + 1), 0.05 * (index + 1))}>
            <img src={circle} alt={`circle-left-${index}`} />
          </motion.div>
        ))}
      </motion.div>

      <div className="app__center-component">
        <div className="app__img">
          <img src={images.webLogo} alt="Web Logo" />
        </div>
        <div className="app__text">{t("header.intro")}</div>
      </div>

      <motion.div className="app__header-circles">
        {[images.flutter, images.redux, images.sass].map((circle, index) => (
          <motion.div
            className="circle-cmp app__flex"
            key={`circle-right-${index}`}
            animate={calculateMovement(
              -0.03 * (index + 1),
              0.05 * (index + 1)
            )}>
            <img src={circle} alt={`circle-right-${index}`} />
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default AppWrap(Header, "home");
