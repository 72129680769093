import React, { useState } from "react";
import { AiFillGithub } from "react-icons/ai";
import { motion } from "framer-motion";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import { useLocalization } from "../../useLocalization";
import "./Work.scss";

const works = [
  {
    title: "web_development",
    description: "web_development_desc",
    imgURL: images.about01,
    projectLink: "#",
    codeLink: "#",
    tags: ["web_app"],
  },
  {
    title: "pass",
    description: "pass_desc",
    imgURL: images.passcode,
    projectLink: "#",
    codeLink: "https://github.com/MeYo0o/pass-production-screenshots/",
    tags: ["mobile_app"],
  },
  {
    title: "ui_ux",
    description: "ui_ux_desc",
    imgURL: images.about03,
    projectLink: "#",
    codeLink: "",
    tags: ["ui_ux"],
  },
  {
    title: "ai_furniture_detection",
    description: "ai_furniture_detection_desc",
    imgURL: images.about04,
    projectLink: "#",
    codeLink:
      "https://github.com/zahraa-ahmed-cyber/ElGamal_Task3/tree/master/Task2",
    tags: ["ai_development"],
  },
  {
    title: "chatty",
    description: "chatty_desc",
    imgURL: images.chatty,
    projectLink: "#",
    codeLink: "https://github.com/MeYo0o/slack-clone-production-screenshots/",
    tags: ["mobile_app"],
  },
  {
    title: "matjar",
    description: "matjar_desc",
    imgURL: images.matjar,
    projectLink: "#",
    codeLink: "https://github.com/MeYo0o/matjar-production-screenshots/",
    tags: ["mobile_app"],
  },
  {
    title: "tiptop",
    description: "tiptop_desc",
    imgURL: images.tiptop,
    projectLink: "#",
    codeLink: "https://github.com/MeYo0o/tiptop-production-screenshots/",
    tags: ["mobile_app"],
  },
  {
    title: "cvme",
    description: "cvme_desc",
    imgURL: images.cvMEcode,
    projectLink: "#",
    codeLink: "https://github.com/MeYo0o/cvme-production-screenshots/",
    tags: ["mobile_app"],
  },
  {
    title: "all_academy",
    description: "all_academy_desc",
    imgURL: images.allAcademy,
    projectLink: "#",
    codeLink: "https://github.com/MeYo0o/allAcademy-production-screenshots",
    tags: ["mobile_app"],
  },
  {
    title: "meal_monkey",
    description: "meal_monkey_desc",
    imgURL: images.foodycode,
    projectLink: "#",
    codeLink: "https://github.com/MeYo0o/foody/",
    tags: ["mobile_app"],
  },
  {
    title: "ecommerce_flutter",
    description: "ecommerce_flutter_desc",
    imgURL: images.about02,
    projectLink: "#",
    codeLink: "https://github.com/MeYo0o/E-Commerce-App",
    tags: ["mobile_app"],
  },
  {
    title: "usms_candidate",
    description: "usms_candidate_desc",
    imgURL: images.about02,
    projectLink: "#",
    codeLink: "https://github.com/MeYo0o/usms-system",
    tags: ["mobile_app"],
  },
  {
    title: "usms_organization",
    description: "usms_organization_desc",
    imgURL: images.about02,
    projectLink: "#",
    codeLink: "https://github.com/MeYo0o/usms-employee",
    tags: ["mobile_app"],
  },
  {
    title: "payment_gateway",
    description: "payment_gateway_desc",
    imgURL: images.about02,
    projectLink: "#",
    codeLink: "https://github.com/MeYo0o/Payment-Gateway-UI",
    tags: ["mobile_app"],
  },
  {
    title: "meals_app",
    description: "meals_app_desc",
    imgURL: images.about02,
    projectLink: "#",
    codeLink: "https://github.com/MeYo0o/meals_app",
    tags: ["mobile_app"],
  },
  {
    title: "shop_app",
    description: "shop_app_desc",
    imgURL: images.about02,
    projectLink: "#",
    codeLink: "https://github.com/MeYo0o/shop_app",
    tags: ["mobile_app"],
  },
  {
    title: "personal_expenses",
    description: "personal_expenses_desc",
    imgURL: images.about02,
    projectLink: "#",
    codeLink: "https://github.com/MeYo0o/personal_expenses",
    tags: ["mobile_app"],
  },
  {
    title: "image_searching",
    description: "image_searching_desc",
    imgURL: images.about04,
    projectLink: "#",
    codeLink:
      "https://github.com/zahraa-ahmed-cyber/ElGamal_Task3/tree/master/Task3",
    tags: ["ai_development"],
  },
  {
    title: "amazon_search_analysis",
    description: "amazon_search_analysis_desc",
    imgURL: images.about04,
    projectLink: "#",
    codeLink:
      "https://github.com/zahraa-ahmed-cyber/Amazon-Mobile-Search-Analysis-US-market-",
    tags: ["ai_development"],
  },
];

const categories = ["all", "ui_ux", "web_app", "mobile_app", "ai_development"];

const Work = () => {
  const { t, locale } = useLocalization();
  const isRTL = locale === "ar";
  const itemsPerPage = 4;
  const [filterWork, setFilterWork] = useState(works);
  const [activeFilter, setActiveFilter] = useState("all");
  const [currentPages, setCurrentPages] = useState(
    categories.reduce((acc, category) => ({ ...acc, [category]: 0 }), {})
  );

  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setCurrentPages((prevPages) => ({ ...prevPages, [item]: 0 }));
    if (item === "all") {
      setFilterWork(works);
    } else {
      setFilterWork(works.filter((work) => work.tags.includes(item)));
    }
  };

  const handlePageChange = (direction) => {
    setCurrentPages((prevPages) => {
      const maxPage = Math.ceil(filterWork.length / itemsPerPage) - 1;
      const newPage = Math.max(
        0,
        Math.min(prevPages[activeFilter] + direction, maxPage)
      );
      return { ...prevPages, [activeFilter]: newPage };
    });
  };

  const filteredWorks = filterWork.slice(
    currentPages[activeFilter] * itemsPerPage,
    (currentPages[activeFilter] + 1) * itemsPerPage
  );

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return `${text.substring(0, maxLength)}...`;
  };

  return (
    <>
      <h2 className="head-text">
        <span>{t("work.our_work")}</span>
      </h2>

      <div className="app__work-filter">
        {categories.map((item, index) => (
          <div
            key={index}
            onClick={() => handleWorkFilter(item)}
            className={`app__work-filter-item app__flex p-text ${
              activeFilter === item ? "item-active" : ""
            }`}>
            {t(`work.categories.${item}`)}
          </div>
        ))}
      </div>

      <motion.div
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio">
        {filteredWorks.map((work, index) => (
          <div className="app__work-item app__flex" key={index}>
            <div className="app__work-img app__flex">
              <img
                src={work.imgURL}
                alt={t(`work.${work.title}`)}
                loading="lazy"
              />

              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{
                  duration: 0.25,
                  ease: "easeInOut",
                  staggerChildren: 0.5,
                }}
                className="app__work-hover app__flex">
                <a href={work.codeLink} target="_blank" rel="noreferrer">
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex">
                    <AiFillGithub />
                  </motion.div>
                </a>
              </motion.div>
            </div>

            <div className="app__work-content app__flex">
              <h4 className="bold-text" style={{ fontFamily: "Raleway" }}>
                {t(`work.${work.title}`)}
              </h4>
              <p
                className="p-text"
                style={{ marginTop: 10, fontFamily: "Raleway" }}>
                {truncateText(t(`work.${work.description}`), 100)}
              </p>
              <div className="app__work-tag app__flex">
                <p className="p-text" style={{ fontFamily: "Raleway" }}>
                  {t(`work.categories.${work.tags[0]}`)}
                </p>
              </div>
            </div>
          </div>
        ))}
      </motion.div>

      <div className="app__work-slider-buttons">
        <div
          className="app__flex"
          onClick={() => handlePageChange(isRTL ? 1 : -1)}>
          {isRTL ? <HiChevronRight /> : <HiChevronLeft />}
        </div>
        <div
          className="app__flex"
          onClick={() => handlePageChange(isRTL ? -1 : 1)}>
          {isRTL ? <HiChevronLeft /> : <HiChevronRight />}
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Work, "app__works"),
  "work",
  "app__primarybg"
);
