import React, { useState } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { motion } from "framer-motion";
import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import { useLocalization } from "../../useLocalization";
import "./Testimonial.scss";

const testimonialsData = [
  {
    imgurl: images.maleUsername,
    feedback: "testimonial.feedback_1",
    name: "Hossam M. [EG]",
    company: "LinkedIn",
  },
  {
    imgurl: images.maleUsername,
    feedback: "testimonial.feedback_2",
    name: "Angel A. [ES]",
    company: "Fiverr",
  },
  {
    imgurl: images.maleUsername,
    feedback: "testimonial.feedback_3",
    name: "Maksym Liman. [UA]",
    company: "Upwork",
  },
  {
    imgurl: images.maleUsername,
    feedback: "testimonial.feedback_4",
    name: "Alex M. [GB]",
    company: "Fiverr",
  },
  {
    imgurl: images.maleUsername,
    feedback: "testimonial.feedback_5",
    name: "Michael S. [US]",
    company: "Fiverr",
  },
];

const brandsData = [
  {
    imgUrl: images.passIcon,
    name: "Get PASS",
  },
  {
    imgUrl: images.troylab,
    name: "TroyLab",
  },
  {
    imgUrl: images.bereau,
    name: "Bereau",
  },
  {
    imgUrl: images.valhko,
    name: "Valhko",
  },
  {
    imgUrl: images.instacondico,
    name: "InstaCodico",
  },
  {
    imgUrl: images.tiptopIcon,
    name: "TipTop",
  },
  {
    imgUrl: images.dafaa,
    name: "Dafaa",
  },
];

const Testimonial = () => {
  const { t, locale } = useLocalization();
  const isRTL = locale === "ar";
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <>
      {testimonialsData.length && (
        <>
          <div className="app__testimonial-item app__flex">
            <img
              src={testimonialsData[currentIndex].imgurl}
              alt={testimonialsData[currentIndex].name}
            />
            <div className="app__testimonial-content">
              <p className="p-text">
                {t(testimonialsData[currentIndex].feedback)}
              </p>
              <div>
                <h4 className="bold-text">
                  {testimonialsData[currentIndex].name}
                </h4>
                <h5 className="p-text">
                  {testimonialsData[currentIndex].company}
                </h5>
              </div>
            </div>
          </div>

          <div className="app__testimonial-btns app__flex">
            <div
              className="app__flex"
              onClick={() =>
                handleClick(
                  currentIndex === 0
                    ? testimonialsData.length - 1
                    : currentIndex - 1
                )
              }>
              {isRTL ? <HiChevronRight /> : <HiChevronLeft />}
            </div>

            <div
              className="app__flex"
              onClick={() =>
                handleClick(
                  currentIndex === testimonialsData.length - 1
                    ? 0
                    : currentIndex + 1
                )
              }>
              {isRTL ? <HiChevronLeft /> : <HiChevronRight />}
            </div>
          </div>
        </>
      )}

      <div className="app__testimonial-brands-slider">
        {brandsData.map((brand) => (
          <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.5, type: "tween" }}
            key={brand.name}>
            <img src={brand.imgUrl} alt={brand.name} />
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Testimonial, "app__testimonial"),
  "testimonial",
  "app__primarybg"
);
