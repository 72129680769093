import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import api from '../assets/api.png';
import cpp from '../assets/cpp.png';
import css from '../assets/css.png';
import figma from '../assets/figma.png';
import flutter from '../assets/flutter.png';
import git from '../assets/git.png';
import graphql from '../assets/graphql.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import mu5 from '../assets/mu5.png';
import node from '../assets/node.png';
import python from '../assets/python.png';
import react from '../assets/react.png';
import redux from '../assets/redux.png';
import sass from '../assets/sass.png';
import typescript from '../assets/typescript.png';
import vue from '../assets/vue.png';

import about01 from '../assets/about01.png';
import about02 from '../assets/about02.png';
import about03 from '../assets/about03.png';
import about04 from '../assets/about04.png';

import profile from '../assets/profile.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';

import adidas from '../assets/adidas.png';
import amazon from '../assets/amazon.png';
import asus from '../assets/asus.png';
import bolt from '../assets/bolt.png';
import nb from '../assets/nb.png';
import skype from '../assets/skype.png';
import spotify from '../assets/spotify.png';
import troylab from '../assets/troylab_logo.jpeg';
import valhko from '../assets/valhko_logo.jpeg';
import instacondico from '../assets/InstaCodico.png';
import bereau from '../assets/ebe_isanad_logo.jpeg';
import linkedin from '../assets/icons8-linkedin-48.png';
import fiverr from '../assets/icons8-fiverr-48.png';
import maleUsername from '../assets/icons8-username-48.png';
import femaleUsername from '../assets/icons8-female-user-48.png';
import dafaa from '../assets/dafa_sa_logo.jpeg';
import webLogo from '../assets/Inno Logo.png';
import logoText from '../assets/Inno text.png';
import passcode from '../assets/Pass.png';
import passIcon from '../assets/getpass_logo.jpeg';

import tiptop from '../assets/TipTop.png';
import matjar from '../assets/Matjar.png';
import foodycode from '../assets/Foody.png';
import cvMEcode from '../assets/CVme.png';
import chatty from '../assets/Chatty.png';
import allAcademy from '../assets/AllAcademy.png';

import tiptopIcon from '../assets/try_tiptop_logo.jpeg';

export default {
  tiptopIcon,
  passIcon,
  email,
  mobile,
  api,
  cpp,
  css,
  figma,
  flutter,
  git,
  graphql,
  html,
  javascript,
  mu5,
  node,
  python,
  react,
  redux,
  sass,
  typescript,
  vue,
  about01,
  about02,
  about03,
  about04,
  profile,
  circle,
  logo,
  adidas,
  amazon,
  asus,
  bolt,
  nb,
  skype,
  spotify,
  troylab,
  passcode,
  valhko,
  bereau,
  linkedin,
  fiverr,
  instacondico,
  tiptop,
  dafaa,
  femaleUsername,
  maleUsername,
  webLogo,
  logoText,
  chatty,
  allAcademy,
  matjar,
  foodycode,
  cvMEcode,
};
