import React, { createContext, useState, useEffect } from "react";
import en from "./locales/en";
import ar from "./locales/ar";

export const LocalizationContext = createContext();

const translations = { en, ar };

export const LocalizationProvider = ({ children }) => {
  const supportedLanguages = ["en", "ar"];
  const browserLanguage = navigator.language.split("-")[0];
  const defaultLanguage = supportedLanguages.includes(browserLanguage)
    ? browserLanguage
    : "en";
  const initialLocale = localStorage.getItem("locale") || defaultLanguage;
  const [locale, setLocale] = useState(initialLocale);
  const [messages, setMessages] = useState(translations[initialLocale]);

  useEffect(() => {
    setMessages(translations[locale]);
  }, [locale]);

  const changeLanguage = (language) => {
    setLocale(language);
    localStorage.setItem("locale", language);
  };

  return (
    <LocalizationContext.Provider value={{ locale, messages, changeLanguage }}>
      {children}
    </LocalizationContext.Provider>
  );
};
