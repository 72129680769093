import React from "react";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
import { images } from "../../constants";
import "./Skills.scss";
import { useLocalization } from "../../useLocalization";

const Skills = () => {
  const { t } = useLocalization();

  const skills = [
    { name: t("skills.flutter"), bgColor: "#ececec", icon: images.flutter },
    { name: t("skills.react"), bgColor: "#ececec", icon: images.react },
    { name: t("skills.python"), bgColor: "#ececec", icon: images.python },
    { name: t("skills.figma"), bgColor: "#ececec", icon: images.figma },
    // Add more skills as needed
  ];

  const experiences = [
    {
      year: t("skills.6_years_of"),
      works: [
        {
          name: t("skills.mobile_applications"),
          company: "Egyptian Bureau of Engineering - Troylab - Get Pass",
          desc: t("skills.project_a_desc"),
        },
      ],
    },
    {
      year: t("skills.3_years_of"),
      works: [
        {
          name: t("skills.web_development"),
          company: "",
          desc: t("skills.project_b_desc"),
        },
      ],
    },
    {
      year: t("skills.2_years_of"),
      works: [
        {
          name: t("skills.ai_development"),
          company: "Valhko - InstaCodico",
          desc: t("skills.project_c_desc"),
        },
      ],
    },
    {
      year: t("skills.1_year_of"),
      works: [
        {
          name: t("skills.ui_ux"),
          company: "",
          desc: t("skills.project_d_desc"),
        },
      ],
    },
  ];

  return (
    <>
      <h2 className="head-text">{t("skills.title")}</h2>

      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {skills.map((skill, index) => (
            <motion.div
              key={index}
              className="app__skills-item app__flex"
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}>
              <div
                className="app__flex"
                style={{ backgroundColor: skill.bgColor }}>
                <img src={skill.icon} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>
        <div className="app__skills-exp">
          {experiences.map((experience, index) => (
            <motion.div key={index} className="app__skills-exp-item">
              <div className="app__skills-exp-year">
                <p className="bold-text">{experience.year}</p>
              </div>
              <motion.div className="app__skills-exp-works">
                {experience.works.map((work, idx) => (
                  <motion.div
                    key={idx}
                    className="app__skills-exp-work"
                    whileInView={{ opacity: [0, 1] }}
                    transition={{ duration: 0.5 }}>
                    <h4 className="bold-text">{work.name}</h4>
                    <p className="p-text">{work.company}</p>
                  </motion.div>
                ))}
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, "app__skills"),
  "skills",
  "app__whitebg"
);
