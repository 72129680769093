const ar = {
  navbar: {
    home: "الرئيسية",
    about: "حول",
    work: "العمل",
    skills: "المهارات",
    contact: "اتصل بنا",
  },
  about: {
    title: "المنتجات المميزة تجذب المزيد من العملاء وتعزز نجاح الأعمال",
    web_development: "تطوير الويب",
    mobile_applications: "تطبيقات الهواتف",
    ui_ux: "تصميم واجهة المستخدم وتجربة المستخدم",
    ai_development: "تطوير الذكاء الاصطناعي",
    description: "نقدم خدمات {{service}}.",
  },
  footer: {
    title: "خذ قهوتك وتحدث معنا",
    thank_you: "شكرا لتواصلك معنا!",
    send_message: "إرسال رسالة",
    sending: "جاري الإرسال...",
    email: "بريدك الإلكتروني",
    message: "رسالتك",
    name: "اسمك",
  },
  header: {
    intro:
      "في إنوفايجن لابز، نتخصص في إنشاء حلول رقمية متقدمة مصممة خصيصًا لاحتياجاتك.",
  },
  skills: {
    title: "المهارات والخبرات",
    flutter: "فلاتر",
    react: "ريآكت",
    python: "بايثون",
    figma: "فيغما",
    mobile_applications: "تطبيقات الهواتف",
    web_development: "تطوير الويب",
    ai_development: "تطوير الذكاء الاصطناعي",
    ui_ux: "تصميم واجهة المستخدم وتجربة المستخدم",
    "6_years_of": "6 سنوات من", // Wrap keys starting with numbers in quotes
    "3_years_of": "3 سنوات من",
    "2_years_of": "سنتان من",
    "1_year_of": "سنة واحدة من",
    project_a_desc: "وصف المشروع أ",
    project_b_desc: "وصف المشروع ب",
    project_c_desc: "وصف المشروع ج",
    project_d_desc: "وصف المشروع د",
  },
  testimonial: {
    feedback_1: "كنت سعيدًا بالعمل معهم وآمل أن تكون سعيدًا أيضًا.",
    feedback_2:
      "أنشأوا لي موقعًا إلكترونيًا وأنا أحبه. أتطلع إلى العمل معهم في المستقبل.",
    feedback_3: "إنهم ماهرون للغاية في تطوير الأجهزة المحمولة.",
    feedback_4: "ساعدوني في مكون إضافي لـ WordPress وأنا سعيد جدًا بالنتيجة.",
    feedback_5:
      "لقد أنشأوا لي موقعًا في غضون أيام بسبب جدول أعمالي المزدحم، وكنت سعيدًا بالنتيجة.",
  },
  work: {
    our_work: "أعمالنا",
    categories: {
      all: "الكل",
      ui_ux: "تصميم واجهة وتجربة المستخدم",
      web_app: "تطبيق ويب",
      mobile_app: "تطبيق موبايل",
      ai_development: "تطوير الذكاء الاصطناعي",
    },
    web_development: "تطوير الويب",
    web_development_desc: "نقدم خدمات تطوير الويب.",
    pass: "باس",
    pass_desc:
      "باس هو تطبيق ثوري لمواقف السيارات يوفر حجز التذاكر عبر التعرف على اللوحات، حجز المواقف مسبقًا، ومحفظة رقمية للدفع بسهولة.",
    ui_ux: "تصميم واجهة وتجربة المستخدم",
    ui_ux_desc: "نقدم خدمات تصميم واجهة وتجربة المستخدم.",
    ai_furniture_detection: "كشف الأثاث باستخدام الذكاء الاصطناعي",
    ai_furniture_detection_desc:
      "حل ذكاء اصطناعي لاكتشاف وتصنيف الأثاث في المشاهد المعمارية.",
    chatty: "تشاتي",
    chatty_desc:
      "تشاتي هو تطبيق مراسلة للأعمال يربط الناس بالمعلومات التي يحتاجون إليها.",
    matjar: "متجر",
    matjar_desc: "متجر هو تطبيق التجارة الإلكترونية متعدد البائعين.",
    tiptop: "تيب توب",
    tiptop_desc:
      "تيب توب هو تطبيق توصيل طعام، بقالة، ماء، وركوب التاكسي في العراق.",
    cvme: "سي في مي",
    cvme_desc:
      "سي في مي هو تطبيق متعدد المنصات يجمع بين لينكد إن وفيسبوك لتوصيل أصحاب العمل والموظفين.",
    all_academy: "كلية الجميع",
    all_academy_desc:
      "كلية الجميع هو تطبيق دراسي يربط بين المدربين والطلاب، على غرار يوديمي.",
    meal_monkey: "ميل مونكي",
    meal_monkey_desc:
      "تطبيق لتوصيل الطعام مع خدمات مثل التسجيل، قوائم الطعام، والدفع عبر Firebase.",
    ecommerce_flutter: "تطبيق التجارة الإلكترونية - Flutter UI",
    ecommerce_flutter_desc:
      "تطبيق التجارة الإلكترونية الكامل مع backend على Firebase، ويشمل التسجيل، قائمة المنتجات، والدفع.",
    usms_candidate: "نظام إدارة المدارس USMS - المرشح",
    usms_candidate_desc:
      "نظام إدارة المدارس للمرشحين، حيث يمكنهم التقديم على الوظائف ومتابعة طلباتهم.",
    usms_organization: "نظام إدارة المدارس USMS - المؤسسة",
    usms_organization_desc:
      "نظام إدارة المدارس لصاحب العمل، ويشمل 9 وحدات لرقمنة عمليات المدرسة.",
    payment_gateway: "واجهة بوابة الدفع",
    payment_gateway_desc: "واجهة بوابة الدفع مع الترجمات والسمات الديناميكية.",
    meals_app: "تطبيق الوجبات",
    meals_app_desc: "طريقة رائعة لتصنيف وجباتك إلى عدة أنواع.",
    shop_app: "تطبيق التسوق",
    shop_app_desc: "تطبيق تسوق يربط بين العديد من البائعين والعملاء.",
    personal_expenses: "تطبيق المصاريف الشخصية",
    personal_expenses_desc: "إدارة مصاريفك الأسبوعية بسهولة.",
    image_searching: "البحث عن الصور في قاعدة بيانات المتجهات",
    image_searching_desc:
      "نظام بحث عن الصور مدعوم بالذكاء الاصطناعي يمكنه استرداد الصور المشابهة بناءً على الميزات المرئية.",
    amazon_search_analysis: "تحليل بحث الهواتف على أمازون (السوق الأمريكي)",
    amazon_search_analysis_desc:
      "تحليل صفحات البحث عن الهواتف على أمازون لاستخلاص الرؤى حول المنتجات، الأسعار، والعروض لشركات مثل سامسونج، هواوي، آبل، وشاومي.",
  },
  app: {
    copyright: "@2024 إنوفايجن لابز",
    all_rights_reserved: "جميع الحقوق محفوظة",
  },
};

export default ar;
