const en = {
  navbar: {
    home: "Home",
    about: "About",
    work: "Work",
    skills: "Skills",
    contact: "Contact",
  },
  about: {
    title: "We Know that Good Products mean Good Business",
    web_development: "Web Development",
    mobile_applications: "Mobile Applications",
    ui_ux: "UI/UX",
    ai_development: "AI Development",
    description: "We provide {{service}} services.",
  },
  footer: {
    title: "Take a coffee & chat with Us",
    thank_you: "Thank you for getting in touch!",
    send_message: "Send Message",
    sending: "Sending...",
    email: "Your Email",
    message: "Your Message",
    name: "Your Name",
  },
  header: {
    intro:
      "At Innovation Labs, we specialize in creating cutting-edge digital solutions tailored to your needs.",
  },
  skills: {
    title: "Skills & Experiences",
    flutter: "Flutter",
    react: "React",
    python: "Python",
    figma: "Figma",
    mobile_applications: "Mobile Applications",
    web_development: "Web Development",
    ai_development: "AI Development",
    ui_ux: "UI/UX",
    "6_years_of": "6 Years of", // Wrap keys starting with numbers in quotes
    "3_years_of": "3 Years of",
    "2_years_of": "2 Years of",
    "1_year_of": "1 Year of",
    project_a_desc: "Description of Project A",
    project_b_desc: "Description of Project B",
    project_c_desc: "Description of Project C",
    project_d_desc: "Description of Project D",
  },
  testimonial: {
    feedback_1:
      "I was happy to work with them and I hope you will be happy too.",
    feedback_2:
      "They created a website for me and I am loving it. I am looking forward to working with them in the future.",
    feedback_3: "They are very skilled in Mobile Development.",
    feedback_4:
      "They helped me with my WordPress plugin and I am very happy with the result.",
    feedback_5:
      "They made me a website in a matter of days because of my tight schedule, I was happy with the result.",
  },
  work: {
    our_work: "Our Work",
    categories: {
      all: "All",
      ui_ux: "UI/UX",
      web_app: "Web App",
      mobile_app: "Mobile App",
      ai_development: "AI Development",
    },
    web_development: "Web Development",
    web_development_desc: "We provide Web Development Services.",
    pass: "Pass",
    pass_desc:
      "Pass is a revolutionary car parking app offering ticketless parking via plate recognition, prebooking for spot reservations, and a digital wallet for easy payments.",
    ui_ux: "UI/UX",
    ui_ux_desc: "We provide UI/UX Services.",
    ai_furniture_detection: "Object Detection with Segmentation for Furniture",
    ai_furniture_detection_desc:
      "An AI solution for object detection and segmentation tailored for furniture within architectural scenes.",
    chatty: "Chatty",
    chatty_desc:
      "Chatty is a messaging app for business that connects people to the information they need.",
    matjar: "Matjar",
    matjar_desc: "Matjar is a multi-vendor e-commerce app.",
    tiptop: "TipTop",
    tiptop_desc:
      "Tiptop is a delivery app that helps with Food, Grocery & Water delivery, and Taxi services.",
    cvme: "CVme",
    cvme_desc:
      "CVME is a LinkedIn/Facebook hybrid multiplatform app that connects employers, companies, and employees.",
    all_academy: "All Academy",
    all_academy_desc:
      "All Academy is a courses app that connects instructors and students, much like Udemy.",
    meal_monkey: "Meal Monkey",
    meal_monkey_desc:
      "A Restaurant & food delivery app with firebase backend and features like user registration, food lists, and payment.",
    ecommerce_flutter: "E-Commerce Complete App - Flutter UI",
    ecommerce_flutter_desc:
      "A complete e-commerce app with Firebase backend, featuring user registration, product listings, and payment.",
    usms_candidate: "USMS - Candidate",
    usms_candidate_desc:
      "Ultimate School Management System - Candidate Side, where employees can apply and track their job applications.",
    usms_organization: "USMS - Organization",
    usms_organization_desc:
      "Ultimate School Management System - Employer Side, offering 9 modules to digitalize a school's operations.",
    payment_gateway: "Payment Gateway UI",
    payment_gateway_desc:
      "This is the UI for the Payment Gateway app with translations and dynamic themes.",
    meals_app: "Meals App",
    meals_app_desc:
      "A great way to categorize your meals into multiple varieties.",
    shop_app: "Shop App",
    shop_app_desc: "A shop app connecting multiple vendors and clients.",
    personal_expenses: "Personal Expenses App",
    personal_expenses_desc: "Manage your weekly expenses easily.",
    image_searching: "Image Searching in Vector Database",
    image_searching_desc:
      "AI-powered image search system that retrieves similar images from a database based on visual similarities.",
    amazon_search_analysis: "Amazon Mobile Search Analysis (US Market)",
    amazon_search_analysis_desc:
      "Analyzing mobile search pages on Amazon to extract product insights, price analysis, and more for Samsung, Huawei, Apple, and Xiaomi.",
  },
  app: {
    copyright: "@2024 InnovationLabs",
    all_rights_reserved: "All rights reserved",
  },
};

export default en;
