/* eslint-disable import/no-unresolved */
import React from "react";
import { motion } from "framer-motion";
import { images } from "../../constants";
import "./About.scss";
import { AppWrap, MotionWrap } from "../../wrapper";
import { useLocalization } from "../../useLocalization";

const About = () => {
  const { t } = useLocalization();

  const abouts = [
    {
      title: t("about.web_development"),
      description: t("about.description", {
        service: t("about.web_development"),
      }),
      imgURL: images.about01,
    },
    {
      title: t("about.mobile_applications"),
      description: t("about.description", {
        service: t("about.mobile_applications"),
      }),
      imgURL: images.about02,
    },
    {
      title: t("about.ui_ux"),
      description: t("about.description", { service: t("about.ui_ux") }),
      imgURL: images.about03,
    },
    {
      title: t("about.ai_development"),
      description: t("about.description", {
        service: t("about.ai_development"),
      }),
      imgURL: images.about04,
    },
  ];

  return (
    <>
      <h2 className="head-text">{t("about.title")}</h2>
      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: "tween" }}
            className="app__profile-item"
            key={about.title + index}>
            <img src={about.imgURL} alt={about.title} />
            <h2 className="bold_text" style={{ marginTop: 20 }}>
              {about.title}
            </h2>
            <p className="p_text" style={{ marginTop: 10 }}>
              {about.description}
            </p>
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, "app__about"),
  "about",
  "app__whitebg"
);
